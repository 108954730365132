<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
              <b-overlay :show="loading">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('common_config.farmer_information') }}</h4>
                    </template>
                    <template v-slot:body>
                        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                            <b-form @submit.prevent="handleSubmit(profileUpdateConfirmation)" @reset.prevent="reset" >
                              <template>
                                <div style="font-size:12px; background-color: #337982; padding:6px">
                                  <h5 class="text-white text-center"> {{ $t('admission_form.gen_inf') }}</h5>
                                </div>
                              </template>
                                <b-row class="mt-4">
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                    <ValidationProvider name="Name (En)" vid="name" rules="required">
                                      <b-form-group class="row" label-cols-sm="4" label-for="name" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{$t('common_config.farmer_name_en')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        type="text"
                                        id="name"
                                        v-model="farmerInfo.name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                  </ValidationProvider>
                                  </b-col>
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                    <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                                      <b-form-group class="row" label-cols-sm="4" label-for="name_bn" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{$t('common_config.farmer_name_bn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        type="text"
                                        id="pay_grade"
                                        v-model="farmerInfo.name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                    <ValidationProvider name="Father Name (En)" vid="father_name" rules="required">
                                      <b-form-group class="row" label-cols-sm="4" label-for="father_name" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{$t('common_config.farmer_father_name_en')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                          type="text"
                                          id="pay_grade"
                                          v-model="farmerInfo.father_name"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                    <ValidationProvider name="Father Name (Bn)" vid="father_name_bn" rules="required">
                                      <b-form-group class="row" label-cols-sm="4" label-for="father_name_bn" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{$t('common_config.farmer_father_name_bn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                          v-model="farmerInfo.father_name_bn"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                    <ValidationProvider name="Mother Name (En)" vid="mother_name_en" rules="required">
                                      <b-form-group class="row" label-cols-sm="4" label-for="mother_name_en" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{$t('admission_form.mother_name_en')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        type="text"
                                        id="mother_name_en"
                                        v-model="farmerInfo.mother_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                    <ValidationProvider name="Mother Name (Bn)" vid="mother_name_bn" rules="required">
                                      <b-form-group class="row" label-cols-sm="4" label-for="mother_name_bn" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{$t('admission_form.mother_name_bn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                          type="text"
                                          id="pay_grade"
                                          v-model="farmerInfo.mother_name_bn"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                    <ValidationProvider name="Date of Birth" vid="date_of_birth" rules="required">
                                      <b-form-group class="row" label-cols-sm="4" label-for="date_of_birth" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{ $t('externalUserIrrigation.date_of_birth') }} <span class="text-danger">*</span>
                                        </template>
                                          <b-form-input
                                            class="date-picker"
                                            v-model="farmerInfo.date_of_birth"
                                            :placeholder="$t('globalTrans.select_date')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                    <ValidationProvider name="Gender" vid="gender_id" rules="required|min_value:1">
                                      <b-form-group class="row" label-cols-sm="4" label-for="gender_id" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{$t('common_config.farmer_gender')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="farmerInfo.gender_id"
                                            :options="genderList"
                                            id="gender_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                    <ValidationProvider name="Blood Group" vid="blood_group_id">
                                      <b-form-group class="row" label-cols-sm="4" label-for="blood_group_id" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{$t('common_config.farmer_blood_group')}}
                                        </template>
                                        <b-form-select
                                          plain
                                          v-model="farmerInfo.blood_group_id"
                                          :options="bloodGroupList"
                                          id="blood_group_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                    <ValidationProvider name="Education Level" vid="education_level_id">
                                      <b-form-group class="row" label-cols-sm="4" label-for="education_level_id" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{$t('common_config.farmer_education_lebel')}}
                                        </template>
                                        <b-form-select
                                          plain
                                          v-model="farmerInfo.education_level_id"
                                          :options="educationLevelList"
                                          id="education_level_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                    <ValidationProvider name="NID" vid="nid_no" rules="required">
                                      <b-form-group class="row" label-cols-sm="4" label-for="nid" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{$t('admission_form.nid_no')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                          type="number"
                                          id="nid_no"
                                          v-model="farmerInfo.nid_no"
                                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                    <ValidationProvider name="Email" vid="email" rules="required|email">
                                      <b-form-group class="row" label-cols-sm="4" label-for="email" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{$t('admission_form.email')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        type="email"
                                        id="email"
                                        v-model="farmerInfo.email"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                    <ValidationProvider name="Mobile No" vid="mobile_no" rules="required|digits:11">
                                      <b-form-group class="row" label-cols-sm="4" label-for="mobile_no" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{$t('admission_form.mobile_no')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                          type="text"
                                          id="mobile_no"
                                          v-model="farmerInfo.mobile_no"
                                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                      <ValidationProvider name="Photo" vid="photo_file">
                                      <b-form-group class="row" label-cols-sm="4" label-for="photo" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                          {{ $t('admission_form.photo') }}
                                        </template>
                                        <b-form-file
                                          id="photo_file"
                                            v-model="photo_file"
                                            :placeholder="$t('common_config.file_chosen')"
                                            style="font-size:11px"
                                            v-on:change="onFileChange"
                                            accept="image/png, image/gif, image/jpeg"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                        </b-form-file>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                </b-row>
                                <template>
                                  <div style="font-size:12px; background-color: #337982; padding:6px">
                                    <h5 class="text-white text-center"> {{ $t('educational_management.present_business_address') }}</h5>
                                  </div>
                                </template>
                                <b-row>
                                  <b-col lg="6" sm="12" class="mt-3">
                                    <ValidationProvider name="Area Type" vid="area_type_id" rules="required|min_value:1">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="area_type_id "
                                          slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="farmerInfo.area_type_id"
                                            id="area_type_id"
                                            :options="areaTypeList"
                                            @change="getAreaTypeData(farmerInfo.area_type_id)"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col lg="6" sm="12" v-show="ItemShow">
                                    <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="division_id"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="farmerInfo.division_id"
                                            :options="divisionList"
                                            id="division_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="ItemShow">
                                    <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="district_id"
                                          slot-scope="{ valid, errors }"
                                        >
                                          <template v-slot:label>
                                            {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-select
                                            plain
                                            v-model="farmerInfo.district_id"
                                            :options="districtList"
                                            id="district_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="PauroshobaItemShow || UnionItemShow">
                                    <ValidationProvider name="Upazila" vid="upazila_id">
                                        <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="upazila_id"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="farmerInfo.upazilla_id"
                                            :options="upazilaList"
                                            id="upazila_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="UnionItemShow">
                                    <ValidationProvider name="Union" vid="union_id">
                                      <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="union_id"
                                        slot-scope="{ valid, errors }"
                                      >
                                        <template v-slot:label>
                                          {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                          plain
                                          v-model="farmerInfo.union_id"
                                          :options="unionList"
                                          id="union_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                          <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="CityCorpItemShow">
                                    <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="city_corporation_id"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                          plain
                                          v-model="farmerInfo.city_corporation_id"
                                          :options="cityCorporationList"
                                          id="city_corporation_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="PauroshobaItemShow">
                                    <ValidationProvider name="Pauroshoba" vid="pauroshoba_id">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="pauroshoba_id"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                          plain
                                          v-model="farmerInfo.pauroshoba_id"
                                          :options="pauroshobaList"
                                          id="pauroshoba_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="ItemShow">
                                    <ValidationProvider name="Ward" vid="ward_id" >
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="ward_id"
                                          slot-scope="{ valid, errors }"
                                        >
                                          <template v-slot:label>
                                            {{ $t('org_pro.ward') }}
                                          </template>
                                          <b-form-select
                                            plain
                                            v-model="farmerInfo.ward_id"
                                            :options="wardList"
                                            id="ward_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                            <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col lg="6" sm="12" v-show="ItemShow">
                                    <ValidationProvider name="Address Details (En)" vid="address_en">
                                      <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="address_en"
                                      >
                                        <template v-slot:label>
                                          {{ $t('globalTrans.address') + ' ' + $t('dealer_panel.en') }}
                                        </template>
                                        <b-form-textarea
                                          id="textarea"
                                          v-model="farmerInfo.address_en"
                                          rows="3"
                                          max-rows="6"
                                        ></b-form-textarea>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="ItemShow">
                                    <ValidationProvider name="Address Details (Bn)" vid="address_bn">
                                      <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="address_bn"
                                      >
                                        <template v-slot:label>
                                          {{ $t('globalTrans.address') + ' ' + $t('dealer_panel.bn') }}
                                        </template>
                                        <b-form-textarea
                                          id="textarea"
                                          v-model="farmerInfo.address_bn"
                                          rows="3"
                                          max-rows="6"
                                        ></b-form-textarea>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                </b-row>
                                <template>
                                  <div style="font-size:12px; background-color: #337982; padding:6px">
                                    <h5 class="text-white text-center"> {{ $t('educational_management.permanent_address') }}</h5>
                                  </div>
                                </template>
                                <b-row>
                                  <b-col  lg="6"  sm="12">
                                    <ValidationProvider>
                                      <b-form-group
                                        class="row"
                                        :label="$t('educational_management.present_business_address')"
                                        label-cols-sm="4"
                                        label-for="present_address"
                                        slot-scope="{ errors }"
                                      >
                                      <div>
                                        <b-form-checkbox
                                          class="mt-2"
                                          id="same_as_present_address"
                                          v-model="same_as_present_address"
                                          :value="true"
                                          :unchecked-value="false"
                                        >
                                          {{$t('admission_form.same')}}
                                        </b-form-checkbox>
                                      </div>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col lg="6" sm="12">
                                    <ValidationProvider name="Area Type" vid="per_area_type_id" rules="required|min_value:1">
                                      <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="per_area_type_id "
                                        slot-scope="{ valid, errors }"
                                      >
                                        <template v-slot:label>
                                          {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                          plain
                                          :disabled="same_as_present_address === 1"
                                          v-model="farmerInfo.per_area_type_id"
                                          id="per_area_type_id"
                                          :options="areaTypeList"
                                          @change="getAreaTypePerData(farmerInfo.per_area_type_id)"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col lg="6" sm="12" v-show="ItemShowPer">
                                    <ValidationProvider name="Division" vid="per_division_id" rules="required|min_value:1">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="per_division_id"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                          plain
                                          :disabled="same_as_present_address === 1"
                                          v-model="farmerInfo.per_division_id"
                                          :options="divisionPerList"
                                          id="per_division_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="ItemShowPer">
                                    <ValidationProvider name="District" vid="per_district_id" rules="required|min_value:1">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="per_district_id"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                          plain
                                          :disabled="same_as_present_address === 1"
                                          v-model="farmerInfo.per_district_id"
                                          :options="districtPerList"
                                          id="per_district_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="PauroshobaItemPerShow || UnionItemPerShow">
                                      <ValidationProvider name="Upazila" vid="per_upazila_id">
                                          <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="per_upazila_id"
                                            slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                            {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                          </template>
                                            <b-form-select
                                              plain
                                              :disabled="same_as_present_address === 1"
                                              v-model="farmerInfo.per_upazilla_id"
                                              :options="upazilaPerList"
                                              id="per_upazila_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="UnionItemPerShow">
                                      <ValidationProvider name="Union" vid="per_union_id">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="per_union_id"
                                              slot-scope="{ valid, errors }"
                                            >
                                              <template v-slot:label>
                                                {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                                plain
                                                :disabled="same_as_present_address === 1"
                                                v-model="farmerInfo.per_union_id"
                                                :options="unionPerList"
                                                id="per_union_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                                <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                                {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="CityCorpItemPerShow">
                                    <ValidationProvider name="City Corporation" vid="per_city_corporation_id">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="per_city_corporation_id"
                                          slot-scope="{ valid, errors }"
                                        >
                                          <template v-slot:label>
                                            {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-select
                                            plain
                                            :disabled="same_as_present_address === 1"
                                            v-model="farmerInfo.per_city_corporation_id"
                                            :options="cityCorporationPerList"
                                            id="per_city_corporation_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="PauroshobaItemPerShow">
                                    <ValidationProvider name="Pauroshoba" vid="per_pauroshoba_id">
                                      <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="per_pauroshoba_id"
                                        slot-scope="{ valid, errors }"
                                      >
                                        <template v-slot:label>
                                          {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                          plain
                                          :disabled="same_as_present_address === 1"
                                          v-model="farmerInfo.per_pauroshoba_id"
                                          :options="pauroshobaPerList"
                                          id="per_pauroshoba_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="ItemShowPer">
                                    <ValidationProvider name="Ward" vid="per_ward_id">
                                      <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="per_ward_id"
                                        slot-scope="{ valid, errors }"
                                      >
                                        <template v-slot:label>
                                          {{ $t('org_pro.ward') }}
                                        </template>
                                        <b-form-select
                                          plain
                                          :disabled="same_as_present_address === 1"
                                          v-model="farmerInfo.per_ward_id"
                                          :options="wardPerList"
                                          id="ward_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                          <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col lg="6" sm="12" v-show="ItemShowPer">
                                    <ValidationProvider name="Address Details (En)" vid="per_address_en">
                                      <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="per_address_en"
                                      >
                                        <template v-slot:label>
                                          {{ $t('globalTrans.address') + ' ' + $t('dealer_panel.en') }}
                                        </template>
                                        <b-form-textarea
                                          id="textarea"
                                          v-model="farmerInfo.per_address_en"
                                          rows="3"
                                          max-rows="6"
                                        ></b-form-textarea>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="ItemShowPer">
                                    <ValidationProvider name="Address Details (Bn)" vid="per_address_bn">
                                      <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="per_address_bn"
                                      >
                                        <template v-slot:label>
                                          {{ $t('globalTrans.address') + ' ' + $t('dealer_panel.bn') }}
                                        </template>
                                        <b-form-textarea
                                          id="textarea"
                                          v-model="farmerInfo.per_address_bn"
                                          rows="3"
                                          max-rows="6"
                                        ></b-form-textarea>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                </b-row>
                                <div class="row">
                                  <div class="col-sm-3"></div>
                                  <div class="col text-right">
                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                    &nbsp;
                                    <!-- <b-button variant="danger" class="mr-1">
                                      <router-link :to="{ path: '/incentive-grant-service/common-configuration/farmer-information'}" size="sm" style="color:#fff !important">
                                        {{ $t('globalTrans.cancel') }}
                                      </router-link>
                                    </b-button> -->
                                  </div>
                                </div>
                            </b-form>
                        </ValidationObserver>
                    </template>
                </iq-card>
              </b-overlay>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { checkExistProfile, applicantProfileUpdate } from '../../../api/routes'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'

export default {
    props: ['id'],
    components: {
      ValidationObserver,
      ValidationProvider
    },
    data () {
      return {
        loading: false,
        saveBtnName: this.$t('globalTrans.update'),
        ItemShow: false,
        inputFieldShow: false,
        ItemShowPer: false,
        CityCorpItemShow: false,
        CityCorpItemPerShow: false,
        PauroshobaItemShow: false,
        PauroshobaItemPerShow: false,
        UnionItemShow: false,
        UnionItemPerShow: false,
        same_as_present_address: false,
        districtList: [],
        upazilaList: [],
        unionList: [],
        cityCorporationList: [],
        pauroshobaList: [],
        wardList: [],
        districtPerList: [],
        upazilaPerList: [],
        unionPerList: [],
        cityCorporationPerList: [],
        pauroshobaPerList: [],
        wardPerList: [],
        photo_file: [],
        farmerInfo: {
          user_id: 0,
          name: '',
          name_bn: '',
          father_name: '',
          father_name_bn: '',
          mother_name: '',
          mother_name_bn: '',
          date_of_birth: '',
          gender_id: 0,
          blood_group_id: 0,
          education_level_id: 0,
          email: '',
          mobile_no: '',
          nid_no: '',
          attachment: null,
          area_type_id: 0,
          city_corporation_id: 0,
          pauroshoba_id: 0,
          division_id: 0,
          district_id: 0,
          upazilla_id: '0',
          union_id: '0',
          ward_id: '0',
          address_en: '',
          address_bn: '',
          is_same: 2,
          per_area_type_id: '0',
          per_city_corporation_id: '0',
          per_pauroshoba_id: '0',
          per_division_id: '0',
          per_district_id: '0',
          per_upazilla_id: '0',
          per_union_id: '0',
          per_ward_id: '0',
          per_address_en: '',
          per_address_bn: ''
        },
        genderList: [
          { value: 1, text: this.$t('common_config.male') },
          { value: 2, text: this.$t('common_config.female') },
          { value: 3, text: this.$t('common_config.other') }
        ]
      }
    },
    created () {
      this.checkProfile({ user_id: this.authUser.user_id })
      this.farmerInfo.user_id = this.authUser.user_id
      this.farmerInfo.name = this.authUser.name
      this.farmerInfo.name_bn = this.authUser.name_bn
      this.farmerInfo.mobile_no = this.authUser.mobile_no
    },
    mounted () {
      flatpickr('.date-picker', {})
      core.index()
    },
    watch: {
      'farmerInfo.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
      },
      'farmerInfo.per_division_id': function (newVal, oldVal) {
        this.districtPerList = this.getDistrictPerList(newVal)
      },
      'farmerInfo.district_id': function (newVal, oldVal) {
        this.cityCorporationList = this.getCityCorporationList(newVal)
        this.upazilaList = this.getUpazilaList(newVal)
      },
      'farmerInfo.per_district_id': function (newVal, oldVal) {
        this.cityCorporationPerList = this.getCityCorporationPerList(newVal)
        this.upazilaPerList = this.getUpazilaPerList(newVal)
      },
      'farmerInfo.upazilla_id': function (newVal, oldVal) {
        this.unionList = this.getUnionList(newVal)
        this.pauroshobaList = this.getPauroshobaList(newVal)
      },
      'farmerInfo.per_upazilla_id': function (newVal, oldVal) {
        this.unionPerList = this.getUnionPerList(newVal)
        this.pauroshobaPerList = this.getPauroshobaPerList(newVal)
      },
      'farmerInfo.city_corporation_id': function (newVal, oldVal) {
        this.wardList = this.getWardListByCityCorportaion(newVal)
      },
      'farmerInfo.per_city_corporation_id': function (newVal, oldVal) {
        this.wardPerList = this.getWardListByCityCorportaionPer(newVal)
      },
      'farmerInfo.union_id': function (newVal, oldVal) {
        this.wardList = this.getWardListByUnion(newVal)
      },
      'farmerInfo.per_union_id': function (newVal, oldVal) {
        this.wardPerList = this.getWardPerListByUnion(newVal)
      },
      same_as_present_address: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.farmerInfo.per_area_type_id = this.farmerInfo.area_type_id
          this.farmerInfo.per_city_corporation_id = this.farmerInfo.city_corporation_id
          this.farmerInfo.per_pauroshoba_id = this.farmerInfo.pauroshoba_id
          this.farmerInfo.per_division_id = this.farmerInfo.division_id
          this.farmerInfo.per_district_id = this.farmerInfo.district_id
          this.farmerInfo.per_upazilla_id = this.farmerInfo.upazilla_id
          this.farmerInfo.per_union_id = this.farmerInfo.union_id
          this.farmerInfo.per_ward_id = this.farmerInfo.ward_id
          this.farmerInfo.per_address_en = this.farmerInfo.address_en
          this.farmerInfo.per_address_bn = this.farmerInfo.address_bn
          this.farmerInfo.is_same = newVal
          this.ItemShowPer = true
          if (this.farmerInfo.per_area_type_id === 1) {
            this.CityCorpItemPerShow = true
            this.PauroshobaItemPerShow = false
            this.UnionItemPerShow = false
          } else if (this.farmerInfo.per_area_type_id === 2) {
            this.CityCorpItemPerShow = false
            this.PauroshobaItemPerShow = true
            this.UnionItemPerShow = false
          } else if (this.farmerInfo.per_area_type_id === 3) {
            this.CityCorpItemPerShow = false
            this.PauroshobaItemPerShow = false
            this.UnionItemPerShow = true
          }
        }
      }
    },
    computed: {
      ...mapGetters({
        authUser: 'Auth/authUser'
      }),
      educationLevelList: function () {
        return this.$store.state.ExternalUserIrrigation.incentiveGrant.educationLevelList.filter(el => el.status === 1).map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
        })
      },
      bloodGroupList: function () {
        const list = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'O-' : 'ও−' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'O+' : 'ও+' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'A-' : 'এ−' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'A+' : 'এ+' },
          { value: 5, text: this.$i18n.locale === 'en' ? 'B-' : 'বি−' },
          { value: 6, text: this.$i18n.locale === 'en' ? 'B+' : 'বি+' },
          { value: 7, text: this.$i18n.locale === 'en' ? 'AB-' : 'এবি−' },
          { value: 8, text: this.$i18n.locale === 'en' ? 'AB+' : 'এবি+' }
        ]
        return list
      },
      divisionList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
      },
      divisionPerList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
      },
      areaTypeList: function () {
        const list = [
          { value: 1, text: this.$i18n.locale === 'bn' ? 'সিটি কর্পোরেশন' : 'City Corpoation' },
          { value: 2, text: this.$i18n.locale === 'bn' ? 'পৌরসভা' : 'Pauroshoba' },
          { value: 3, text: this.$i18n.locale === 'bn' ? 'ইউনিয়ন' : 'Union' }
        ]
        return list
      }
    },
    methods: {
      async checkProfile (userId) {
          const result = await RestApi.getData(incentiveGrantServiceBaseUrl, checkExistProfile, userId)
          this.loading = false
          if (result.success) {
            this.$router.push({ name: 'external_user.grant_panel.profile_view' })
          }
      },
      onFileChange (e) {
        const bytesize = e.target.files[0].size
        const kbsize = bytesize / 1024
        if (kbsize > 100) {
          this.farmerInfo.attachment = ''
          this.photo_file = []
          this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: this.$t('dealer_panel.signsize'),
            color: '#bf9006'
          })
          return
        }
        const input = e.target
        if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
            this.farmerInfo.attachment = e.target.result
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.photo_file = null
        }
      },
      getAreaTypeData (typeId) {
        this.ItemShow = true
        if (typeId === 1) {
          this.CityCorpItemShow = true
          this.PauroshobaItemShow = false
          this.UnionItemShow = false
        } else if (typeId === 2) {
          this.CityCorpItemShow = false
          this.PauroshobaItemShow = true
          this.UnionItemShow = false
        } else if (typeId === 3) {
          this.CityCorpItemShow = false
          this.PauroshobaItemShow = false
          this.UnionItemShow = true
        }
      },
      getAreaTypePerData (typeId2) {
        this.ItemShowPer = true
        if (typeId2 === 1) {
          this.CityCorpItemPerShow = true
          this.PauroshobaItemPerShow = false
          this.UnionItemPerShow = false
        } else if (typeId2 === 2) {
          this.CityCorpItemPerShow = false
          this.PauroshobaItemPerShow = true
          this.UnionItemPerShow = false
        } else if (typeId2 === 3) {
          this.CityCorpItemPerShow = false
          this.PauroshobaItemPerShow = false
          this.UnionItemPerShow = true
        }
      },
      getDistrictList (divisionId = null) {
        const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)
        if (divisionId) {
          return districtList.filter(district => district.division_id === divisionId)
        }
        return districtList
      },
      getDistrictPerList (divisionPerId = null) {
        const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)
        if (divisionPerId) {
          return districtList.filter(district => district.division_id === divisionPerId)
        }
        return districtList
      },
      getUpazilaList (districtId = null) {
        const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
        if (districtId) {
          return upazilaList.filter(upazila => upazila.district_id === districtId)
        }
        return upazilaList
      },
      getUpazilaPerList (districtId = null) {
        const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
        if (districtId) {
          return upazilaList.filter(upazila => upazila.district_id === districtId)
        }
        return upazilaList
      },
      getUnionList (upazilaId = null) {
        const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.status === 0)
        if (upazilaId) {
          return unionList.filter(union => union.upazilla_id === upazilaId)
        }
        return unionList
      },
      getUnionPerList (upazilaId = null) {
        const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.status === 0)
        if (upazilaId) {
          return unionList.filter(union => union.upazilla_id === upazilaId)
        }
        return unionList
      },
      getCityCorporationList (districtId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
        return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
      },
      getCityCorporationPerList (districtId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
        return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
      },
      getPauroshobaList (upazillaId = null) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
        if (upazillaId) {
          return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text_en }
            }
          })
        }
      },
      getPauroshobaPerList (upazillaId = null) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
        if (upazillaId) {
          return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text_en }
            }
          })
        }
      },
      getWardListByCityCorportaion (cityCorpId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList
        const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
        return wardObjectList.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      },
      getWardListByCityCorportaionPer (cityCorpId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList
        const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
        return wardObjectList.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      },
      getWardListByPauroshoba (pauroshobaId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList
        const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 0)
        return wardObjectList.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      },
      getWardListByUnion (unionId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList
        const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 0)
        return wardObjectList.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      },
      getWardPerListByUnion (unionPerId) {
        const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList
        const wardObjectList = objectData.filter(item => item.union_id === unionPerId && item.status === 0)
        return wardObjectList.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      },
      getFarmerInfoData () {
        const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.$route.query.id))
        return JSON.parse(JSON.stringify(tmpData))
      },
      profileUpdateConfirmation () {
        this.$swal({
          title: this.$t('dealer_panel.final_save_msg'),
          showCancelButton: true,
          confirmButtonText: this.$t('globalTrans.yes'),
          cancelButtonText: this.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.saveData()
          }
        })
      },
      async saveData () {
        this.loading = true
        let result = null
        result = await RestApi.postData(incentiveGrantServiceBaseUrl, applicantProfileUpdate, this.farmerInfo)

        if (result.success) {
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$router.push({ name: 'external_user.grant_panel.profile_view' })
        } else {
          if (result.errors) {
            this.$refs.form.setErrors(result.errors)
            this.$toast.error({
              title: 'Error',
              message: result.message,
              color: '#D6E09B'
            })
          } else {
            this.$toast.error({
              title: 'Error',
              message: result.message,
              color: '#D6E09B'
            })
          }
        }
        this.loading = false
      }
    }
}
</script>
